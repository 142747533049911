//@ts-nocheck
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch, useParams } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import HomePage from './components/Homepage';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [meetingStatus, meetinStatus] = useState<any>(null);
  const [meetData, setMeetData] = useState<any>({});
  const params = useParams();
  const { getAppoinmentDetails } = useAppState();

  async function fetched(id: any) {
    try {
      await getAppoinmentDetails(id)
        .then((res: any) => {
          if (!res.data) throw 'not get appoinments';
          setMeetData(res?.data?.[0]);
          // setData(res.data || [])
        })
        .catch(() => {
          // console.error("network error when call getAppoinments", error);
        });
    } catch (error) {
      console.error('network error when call getAppoinments', error);
    }
  }
  useEffect(() => {
    const urlParams: any = new URLSearchParams(window.location.search);
    const mid: any = urlParams.get('mid');

    fetched(mid);
    meetinStatus('not_started');
  }, []);

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/room">
              <VideoApp />
            </PrivateRoute>
            <Route path="/login">
              <LoginPage />
            </Route>
            {/* <Redirect to="/room/:URLRoomName" /> */}
          </Switch>
        </AppStateProvider>
      </Router>
      <ToastContainer position="bottom-right" autoClose={3000} theme="colored" hideProgressBar />
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
