// @ts-nocheck

import React, { ChangeEvent, useEffect, useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';
import { BrowserRouter, Switch, Route, useHistory, Link, useLocation } from 'react-router-dom';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import HomePage from './components/Homepage';
import { useAppState } from './state';
import { getCookie } from './utils';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const height = useHeight();
  const location = useLocation();
  const { user, getRoomFromTwillo, fetchRoomHandler, getRoomDetails } = useAppState();
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [roomDetails, setRoomDetails] = useState({
    allowToJoin: false,
    loading: true,
    loaded: true,
  });
  const history = useHistory();
  const { authRoomCheck } = useAppState();

  useEffect(() => {
    async function authroom() {
      const urlParams: any = new URLSearchParams(window.location.search);
      const mid: any = urlParams.get('mid');
    }
    authroom();
  }, []);

  useEffect(() => {
    if (roomName && name) {
    }
  }, [roomName, name]);
  useEffect(() => {
    const urlParams: any = new URLSearchParams(window.location.search);
    const mid: any = urlParams.get('mid');
    const name: any = urlParams.get('name');
    if (!mid) {
      // window.location.href = '/';
    } else {
      setRoomName(mid);
      async function authroom() {
        await authRoomCheck({
          roomname: mid || '',
        }).then((response: any) => {
          // console.log(">>>>>>>", response);
          // if (response && response[0] && !response[0]?.expired) {
          //   return false;
          // }
          // alert("room ended");
          // window.location.href = "/";
          // return true;
        });
      }
      authroom();
    }
    if (!name) {
      setRoomDetails({
        allowToJoin: false,
        loading: true,
        loaded: false,
      });
      // window.location.href = '/';
    } else {
      setName(name);
    }
  }, []);

  async function fetched() {
    try {
      let response = await getRoomFromTwillo(roomName);
      if (!response) {
        console.error('network error in getRoomFromTwillo');
        return false;
      }
      if (response && response.rooms.length === 0) {
        return true;
      }
      await fetchRoomHandler(response.rooms[0]);
    } catch (error) {
      console.error('network error app : ', error);
      fetched();
    }
  }

  const fetchRoomDetails = () => {
    getRoomDetails(roomName, name).then(data => {
      const urlParams: any = new URLSearchParams(window.location.search);
      const adminLink: any = urlParams.get('a');
      const uid: any = getCookie('vuid');
      const mid: any = urlParams.get('mid');
      console.log('efe', adminLink, data, data?.userId, uid);
      if (data) {
        if (adminLink && data?.userId != uid) {
          window.location.href = `/room/?mid=${mid}&name=`;
        }

        setRoomDetails(data);
      }
    });
  };
  useEffect(() => {
    if (roomName && name) {
      window.joiningInterval = setInterval(() => {
        fetchRoomDetails();
      }, 5000);
      fetchRoomDetails();
    }
    return () => {
      clearInterval(window.joiningInterval);
    };
  }, [roomName, name]);

  // @ts-ignore
  const handleSubmit = (joinName, mid) => {
    window.location.href = `/room/?mid=${mid}&name=${joinName}`;
  };

  console.log('Wdwfw', roomDetails);
  return roomDetails.loaded ? (
    <></>
  ) : (
    <Container style={{ height }}>
      {roomState === 'disconnected' || !name || !roomName ? (
        <PreJoinScreens
          handleSubmit={handleSubmit}
          roomName={roomName}
          setRoomName={setRoomName}
          name={name}
          setName={setName}
          user={user}
          roomDetails={roomDetails}
        />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar roomName={roomName} name={name} roomDetails={roomDetails} />
        </Main>
      )}
    </Container>
  );
}
