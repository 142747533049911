// @ts-nocheck
import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase/auth';
import { getCookie } from '../utils';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  getAppoinments(): any;
  getCustomers(): any;
  authRoomCheck(data: any): any;
  createAppoinments(data: any): any;
  fetchRoomHandler(data: any): any;
  getRoomFromTwillo(data: any): any;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  Anytype: any;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  appoinments: any;
  setAppoinments: React.Dispatch<React.SetStateAction<any>>;
  getAppoinmentDetails: any;
  getRoomDetails: any;
  updateRoomDetails(data: any): any;
}

function getCookieValue(cookieName: any) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return value;
    }
  }
  return null;
}

function decodeBase64(encodedString: any) {
  try {
    const decodedString1 = decodeURIComponent(encodedString);
    const decodedString = atob(decodedString1);
    return decodedString;
  } catch (error) {
    console.error('Error decoding base64:', error);
    return null;
  }
}
export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [appoinments, setAppoinments] = useState([]);
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled,
    setAppoinments,
    appoinments,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const endpoint = process.env.REACT_APP_BASE_API + '/token';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            create_room: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      getAppoinments: async () => {
        const endpoint = process.env.REACT_APP_BASE_API + '/appoinments';

        return fetch(endpoint, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      getAppoinmentDetails: async (id: any) => {
        const endpoint = process.env.REACT_APP_BASE_API + `/appoinment/${id}`;

        return fetch(endpoint, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },

      getRoomDetails: async (id: any, name: any) => {
        let endpoint = process.env.REACT_APP_BASE_API + `/room/${id}/${name}`;
        const vuidCookieValue = getCookieValue('vuid');
        const uid: any = decodeBase64(vuidCookieValue);
        if (uid) {
          endpoint = endpoint + `/${uid}`;
        }
        return fetch(endpoint, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      updateRoomDetails: async (data: any) => {
        const uid: any = getCookie('vuid');
        console.log('updateRoomDetails vuid : ', uid);
        const urlParams: any = new URLSearchParams(window.location.search);
        const roomname: any = urlParams.get('mid');
        let endpoint = process.env.REACT_APP_BASE_API + `/updateappointment/${roomname}`;
        if (uid) {
          endpoint += `/${uid}`;
        }
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        await fetch(endpoint, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(data),
        })
          .then(res => res.json())
          .catch(err => {
            console.log('error', err);
          });
      },

      authRoomCheck: async data => {
        const endpoint = process.env.REACT_APP_BASE_API + '/appoinments/isexpired';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify that you're sending JSON data
          },
          body: JSON.stringify(data),
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      getCustomers: async () => {
        const endpoint = process.env.REACT_APP_BASE_API + '/customers';

        console.log('dwqdqw', endpoint);

        return fetch(endpoint, {
          method: 'GET',
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      getRoomFromTwillo: async data => {
        const twilioAccountSid = 'ACae34c2d011349c5201efee4e83732457';
        const twilioAuthToken = '0c6ea8f3b61016500acb80fa71cb7d68';
        const endpoint = `https://video.twilio.com/v1/Rooms?UniqueName=${encodeURIComponent(data)}`;
        const headers = new Headers();
        await headers.append('Authorization', 'Basic ' + btoa(`${twilioAccountSid}:${twilioAuthToken}`));
        return await fetch(endpoint, {
          method: 'GET',
          headers: headers,
        })
          .then(response => response.json())
          .catch(err => setError(err));
      },
      createAppoinments: async (data: any) => {
        const endpoint = process.env.REACT_APP_BASE_API + '/appoinments/createappoinment';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify that you're sending JSON data
          },
          body: JSON.stringify(data),
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      fetchRoomHandler: async (data: any) => {
        const endpoint = process.env.REACT_APP_BASE_API + '/rooms/fetchroom';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify that you're sending JSON data
          },
          body: JSON.stringify(data),
        })
          .then(res => res.json())
          .catch(err => setError(err));
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = process.env.REACT_APP_BASE_API + '/recordingrules';

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res?.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getAppoinments: StateContextType['getAppoinments'] = () => {
    setIsFetching(true);
    return contextValue
      .getAppoinments()
      .then((res: any) => {
        setAppoinments(res?.data);
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const authRoomCheck: StateContextType['authRoomCheck'] = data => {
    setIsFetching(true);
    return contextValue
      .authRoomCheck(data)
      .then((res: any) => {
        setAppoinments(res?.data);
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const getCustomers: StateContextType['getCustomers'] = () => {
    setIsFetching(true);
    return contextValue
      .getCustomers()
      .then((res: any) => {
        setAppoinments(res?.data);
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const getRoomFromTwillo: StateContextType['getRoomFromTwillo'] = data => {
    setIsFetching(true);
    return contextValue
      .getRoomFromTwillo(data)
      .then((res: any) => {
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const createAppoinments: StateContextType['createAppoinments'] = data => {
    setIsFetching(true);
    return contextValue
      .createAppoinments(data)
      .then((res: any) => {
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  const fetchRoomHandler: StateContextType['fetchRoomHandler'] = data => {
    setIsFetching(true);
    return contextValue
      .fetchRoomHandler(data)
      .then((res: any) => {
        setIsFetching(false);
        return res;
      })
      .catch((err: any) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getToken,
        updateRecordingRules,
        getAppoinments,
        authRoomCheck,
        createAppoinments,
        fetchRoomHandler,
        getRoomFromTwillo,
        getCustomers,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
