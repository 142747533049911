import React from 'react';

export default function VideoLogo() {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1417 242" width={200} height={100}>
      <title>deposytlogo-svg</title>
      <style dangerouslySetInnerHTML={{ __html: '\n      .s0 { fill: #ffffff } \n    ' }} />
      <g id="Layer">
        <g id="Layer">
          <path
            id="Layer"
            className="s0"
            d="m178.2 13.7v43.5c0 1.3-0.5 2.5-1.4 3.4l-125 124c-0.9 0.9-2.2 1.4-3.4 1.4h-43.6c-2.6 0-4.8-2.2-4.8-4.8v-43.6c0-1.2 0.5-2.5 1.4-3.4l125.1-123.9c0.9-0.9 2.1-1.4 3.3-1.4h43.6c2.7 0 4.8 2.1 4.8 4.8z"
          />
          <path
            id="Layer"
            className="s0"
            d="m0 54.4v-40.7c0-2.7 2.1-4.8 4.8-4.8h40.7c2.7 0 4.8 2.1 4.8 4.8v19.7c0 1.3-0.5 2.5-1.4 3.4l-21 21c-0.9 0.9-2.1 1.4-3.3 1.4h-19.8c-2.6 0-4.8-2.1-4.8-4.8z"
          />
          <path
            id="Layer"
            className="s0"
            d="m173.4 135.7c2.7 0 4.8 2.1 4.8 4.8v40.7c0 2.7-2.1 4.8-4.8 4.8h-40.7c-2.7 0-4.8-2.1-4.8-4.8v-19.7c0-1.3 0.5-2.5 1.4-3.4l21-21c0.9-0.9 2.1-1.4 3.4-1.4h19.7z"
          />
        </g>
        <g id="Layer">
          <path
            id="Layer"
            fillRule="evenodd"
            className="s0"
            d="m410.8 95.9c-0.1 34.7-13.7 90.2-89 90.2h-77.7v-180.5h77.7c76.4 0 88.9 55.6 88.9 90.3zm-40.6 0c0-20.3-7.8-54.5-48.3-54.5h-38.4v108.9h38.4c40.2 0 48.3-35 48.3-54.4z"
          />
          <path
            id="Layer"
            fillRule="evenodd"
            className="s0"
            d="m572.9 122.8h-115.4c2.7 19.3 14.3 28.9 39.7 28.9 23.4 0 35-6.5 38.6-16h38c-4 31.1-29.1 50.3-76.7 50.3-47.5 0-77.7-24.9-77.7-73 0-48.1 28.9-73.1 77.7-73.1 20.5 0 36.8 5.2 49 13.7v-0.1c28 18.9 30.5 49.5 28.2 69.3h-1.4zm-113.9-25.9h76.4c-3.2-10.8-10.3-22.6-38.3-22.6-21.6 0-33.6 7.9-38.1 22.6z"
          />
          <path
            id="Layer"
            fillRule="evenodd"
            className="s0"
            d="m748.2 113.9c0 19.5-6.8 72.4-67.5 72.4-27.2 0-43.7-7.6-53.5-20.2h-1.8v75.1h-39.2v-199.6h39.2v21.2h2.2c9.9-12.8 26.2-21.2 53-21.2 61.2 0 67.6 51.2 67.6 72.3zm-37.4 0c0-11.2-4-38.6-41.6-38.6-33.8 0-40.8 19-40.8 38.6 0 21.9 7 38.7 40.9 38.7 37.3-0.1 41.5-28.3 41.5-38.7z"
          />
          <path
            id="Layer"
            fillRule="evenodd"
            className="s0"
            d="m918.4 112.4c0 50.7-36.8 73.6-80.6 73.6-43.9 0-80.7-23.6-80.7-73.6 0-50.1 36.8-73.7 80.7-73.7 43.8 0 80.6 23.6 80.6 73.7zm-38.6-0.1c0-26.6-17.5-37.8-42-37.8-24.6 0-42.1 10.7-42.1 37.8 0 27.2 17.5 37.9 42.1 37.9 24.5 0 42-11.6 42-37.9z"
          />
          <path
            id="Layer"
            className="s0"
            d="m1070.6 143.2c0 35.8-25.7 42.8-70.1 42.8-32.7 0-55.2-5.9-66.8-17.6-10-10.1-9.9-22.3-9.8-29.6v-2.8h40.3v1.2c0 12.4 9.2 17.9 35.3 17.9 15.7 0 30.7-2 30.7-11.4 0-10.8-14-12.9-29.6-14.7-2.1-0.3-4.1-0.5-6.1-0.8-41.6-5.5-67.4-14.7-67.4-43.5 0-20.2 11.7-44.3 67.2-44.3 59.9 0 72.5 26.6 72.5 48.8v1.3h-39.9v-1.3c0-11.4-9.7-17.4-32.6-17.4-22.9 0-26.5 8-26.5 12.4 0 6.4 4.4 9.9 29.6 13.7l3.3 0.5c32.8 4.9 69.9 10.4 69.9 44.8z"
          />
          <path
            id="Layer"
            className="s0"
            d="m1230.4 41.6l-79.8 199.6h-42.7l16.1-45.5-4.4-9.5-55.7-144.6h41.4l41.9 108.8 41.7-108.8z"
          />
          <path
            id="Layer"
            className="s0"
            d="m1295.1 126.2v2.9c0 21.1 9.5 18 47 16v38.3c0 0-7.8 2.7-47 2.7-39.2 0-39.3-32.3-39.3-53.3v-58h-31.9l12.6-33h19.3v-41.8h39.3v41.8h47v33h-47z"
          />
        </g>
      </g>
      <g id="Layer">
        <path id="Layer" className="s0" d="m1345.9 6.2v-6.2h28v6.2h-10.3v29.9h-7.4v-29.9h-10.3z" />
        <path
          id="Layer"
          className="s0"
          d="m1406.2 0h10.3v36.1h-7.1v-27.6l-9.2 27.6h-5.9l-9.3-27.6v27.6h-7.2v-36.1h10.8l8.8 26.8 8.8-26.8z"
        />
      </g>
    </svg>
  );
}
