// @ts-nocheck

import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useHistory } from 'react-router-dom';
import { useAppState } from '../../state';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens(props: any) {
  const { user, roomName, setRoomName, name, setName, handleSubmit, roomDetails } = props;
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const history = useHistory();
  const [mediaError, setMediaError] = useState<Error>();
  const { authRoomCheck } = useAppState();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    async function fetched() {
      const urlParams: any = new URLSearchParams(window.location.search);
      const mid: any = urlParams.get('mid');
      await authRoomCheck({ roomname: mid || '' }).then((response: any) => {});
    }
    fetched();
  }, []);

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {!name || !roomName ? (
        <RoomNameScreen
          roomDetails={roomDetails}
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      ) : (
        <DeviceSelectionScreen roomDetails={roomDetails} name={name} roomName={roomName} />
      )}
    </IntroContainer>
  );
}

export function randomString(numberLength: any) {
  const section1 = Math.floor(Math.random() * 900) + 100;
  const section2 = Math.floor(Math.random() * 900) + 100;
  const section3 = Math.floor(Math.random() * 900) + 100;

  return `DP${section1}-${section2}-${section3}`;
}
