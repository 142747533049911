// @ts-nocheck
import { toast } from 'react-toastify';
import React, { useState, useRef, useEffect } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../icons/BackgroundIcon';
import CollaborationViewIcon from '@material-ui/icons/AccountBox';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridViewIcon from '@material-ui/icons/Apps';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '../../../icons/SettingsIcon';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '250px',
    maxWidth: '250px',
    '&:hover': {
      backgroundColor: 'transparent', // Set the background color to transparent on hover
    },
  },
  // ... other styles
}));

function findUnmatchedObjects(array1, array2) {
  const namesArray1 = array1.map(obj => obj.name);
  const namesArray2 = array2.map(obj => obj.name);

  const unmatchedNames = namesArray1.filter(name => !namesArray2.includes(name));

  const unmatchedObjectsArray1 = array1.filter(obj => unmatchedNames.includes(obj.name));
  const unmatchedObjectsArray2 = array2.filter(obj => unmatchedNames.includes(obj.name));

  return {
    unmatchedObjectsArray1,
    unmatchedObjectsArray2,
  };
}
export default function Menu(props: { buttonClassName?: string }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [invitations, setInvitaions] = useState(false);
  const [invitationShow, setInvitationShow] = useState(false);
  const [flag, setFlage] = useState(false);
  const [isFetchingInvitation, setIsFetchingInvitation] = useState(false);

  const { isFetching, updateRecordingRules, roomType, setIsGalleryViewActive, isGalleryViewActive } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  useEffect(() => {
    if (props?.roomDetails?.participants && props?.roomDetails?.isAdmin) {
      const filtedData = props.roomDetails.participants?.filter(r => {
        return !r.allowJoin && r.name !== props.name;
      });
      if (flag) {
        const filtedDataNew = Array.isArray(invitations) ? invitations : [];

        const { unmatchedObjectsArray1 } = findUnmatchedObjects(filtedData, filtedDataNew);
        if (unmatchedObjectsArray1?.length > 0) {
          unmatchedObjectsArray1?.map(item => toast.info(`${item.name} is trying to join`));
          console.log('unmatchedObjectsArray1  :', unmatchedObjectsArray1);
        }
      }
      setFlage(true);
      setInvitaions(filtedData);
    }
  }, [props.roomDetails]);

  const accecptInvitations = (data: any) => {
    setIsFetchingInvitation(true);
    if (invitations && invitations.length && props.roomName && data?.name) {
      const endpoint = process.env.REACT_APP_BASE_API + `/allow/${props.roomName}/${data?.name}`;

      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      return fetch(endpoint, {
        method: 'POST',
        headers: myHeaders,
      })
        .then(res => res.json())
        .then((response: any) => {
          if (response) {
            const filtedData = response?.filter(r => {
              return !r.allowJoin && r.name !== props.name;
            });

            console.log('effew', filtedData);
            setInvitaions(filtedData || []);
            // return response;
          }
          setIsFetchingInvitation(false);
          if (response?.error) {
            return false;
          }
          console.log('response accecpt invitation : ', response);
        })
        .catch(err => {
          setIsFetchingInvitation(false);
          return false;
        });
    }
    setIsFetchingInvitation(false);
  };

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            More
            <ExpandMoreIcon />
          </>
        )}
      </Button>
      {console.log('dwdqwd', invitations)}

      {props?.roomDetails?.isAdmin && invitations?.length > 0 ? (
        <Button
          onClick={() => setInvitationShow(isOpen => !isOpen)}
          ref={anchorRef}
          className={props.buttonClassName}
          data-cy-more-button
        >
          {isMobile ? (
            <MoreIcon />
          ) : (
            <>
              Invitations
              <ExpandMoreIcon />
            </>
          )}
        </Button>
      ) : null}

      <MenuContainer
        open={invitationShow}
        onClose={() => setInvitationShow(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        {invitations?.length > 0 &&
          invitations.map((item: any) => (
            <MenuItem className={classes.menuItem}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconContainer>
                  <BackgroundIcon />
                </IconContainer>
                <Typography variant="body1">{item?.name}</Typography>
              </div>
              <Button color="primary" onClick={() => accecptInvitations(item)}>
                allow
              </Button>
            </MenuItem>
          ))}
      </MenuContainer>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography variant="body1">Backgrounds</Typography>
          </MenuItem>
        )}

        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )}

        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            VideoRoomMonitor.toggleMonitor();
            setMenuOpen(false);
          }}
        >
          <IconContainer>
            <SearchIcon style={{ fill: '#707578', width: '0.9em' }} />
          </IconContainer>
          <Typography variant="body1">Room Monitor</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <IconContainer>
            {isGalleryViewActive ? (
              <CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
            ) : (
              <GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
            )}
          </IconContainer>
          <Typography variant="body1">{isGalleryViewActive ? 'Speaker View' : 'Gallery View'}</Typography>
        </MenuItem>

        <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
