//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { getCookie } from '../../../utils';
import { LocalDataTrack } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: any) {
  const { className, roomDetails, name } = props;
  const classes = useStyles();
  const { room } = useVideoContext();
  const { updateRoomDetails } = useAppState();
  const [messageData, setMessageData] = useState([]);

  const broadcastCustomEvent = useCallback(
    async (eventName, eventData) => {
      try {
        // Create a new LocalDataTrack
        const dataTrack = new LocalDataTrack();

        // Publish the data track
        await room.localParticipant.publishTrack(dataTrack);

        // Send the custom event data to all participants
        room.participants.forEach(participant => {
          // Skip the local participant
          if (participant !== room.localParticipant) {
            dataTrack.send(
              JSON.stringify({
                event: eventName,
                data: eventData,
              })
            );
          }
        });

        // Wait for a while before unpublishing the track (you might need to adjust the duration)
        await new Promise(resolve => setTimeout(resolve, 5000));

        // Unpublish the data track
        room.localParticipant.unpublishTrack(dataTrack);
      } catch (error) {
        console.error('Error publishing/unpublishing track:', error);
      }
    },
    [room]
  );

  const handleDisconnect = async () => {
    // broadcastCustomEvent('myCustomeEvent', { key: 'value' });
    const uid: any = getCookie('vuid');
    console.log('efewf', roomDetails?.userId, uid);
    if (roomDetails?.userId == uid) {
      let payload = { expired: true, roomname: room?.name };

      if (!roomDetails?.allow) {
        let filtedData = [];
        await props.roomDetails?.participants?.map(r => {
          if (r.name !== props?.name && !r?.isAdmin) {
            filtedData.push({
              ...r,
              allowJoin: false,
            });
            return true;
          } else {
            filtedData.push({
              ...r,
            });
            return true;
          }
        });

        console.log('efewf', filtedData);
        if (filtedData.length > 0) {
          payload = { ...payload, participants: JSON.stringify(filtedData) };
        }
      }

      await updateRoomDetails(payload);
    }

    room!.disconnect();
  };

  useEffect(() => {
    if (room) {
      // Listen for participantConnected event
      room.on('participantConnected', participant => {
        console.log(`Participant connected: ${participant.identity}`);

        console.log('dataTrack 4444 : ', participant);
        // Listen for custom events from the participant
        participant.on('trackSubscribed', track => {
          console.log('dataTrack 5555 : ', track);
          // Handle custom event data sent by the participant
          if (track.kind === 'data') {
            track.on('message', (data: any) => {
              try {
                // Parse the received data as JSON
                const parsedData = JSON.parse(data);
                setMessageData({ participant, data: parsedData });
                console.log(`data received custom event from ${participant.identity}:`, parsedData['data']);
              } catch (error) {
                console.log('data Error parsing data:', error);
              }
              // Handle the custom event data here
            });
          }
        });
      });

      // Listen for participantDisconnected event
      room.on('participantDisconnected', participant => {
        console.log(`Participant disconnected: ${participant.identity}`);
      });
    }

    // Cleanup event listeners when the component unmounts
    return () => {
      if (room) {
        room.removeAllListeners();
      }
    };
  }, [room]);

  useEffect(() => {
    console.log('room', room);
  }, [room]);

  useEffect(() => {
    if (roomDetails?.expired) {
      room!.disconnect();
    }
  }, [roomDetails]);

  return (
    <Button onClick={handleDisconnect} className={clsx(classes.button, className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
