// @ts-nocheck
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
function randomString(numberLength: any) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  if (!numberLength) {
    numberLength = Math.floor(Math.random() * chars.length);
  }

  var str = '';
  for (var i = 0; i < numberLength; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const [joinName, setJoinName] = useState<string>('');
  const [mid, setMid] = useState<string>(roomName || '');
  const [error, setError] = useState('');

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setJoinName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMid(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  useEffect(() => {
    // const urlParams: any = new URLSearchParams(window.location.search);
    // const mid: any = urlParams.get('mid');
    // const name: any = urlParams.get('name');

    if (roomName) {
      setMid(roomName);
    }
  }, [roomName]);
  // @ts-ignore
  const handleRoomSubmit = e => {
    e.preventDefault();

    if (!mid) {
      return;
    }
    const endpoint = process.env.REACT_APP_BASE_API + `/appointment/${mid}`;

    return fetch(endpoint, {
      method: 'GET',
    })
      .then(res => res.json())
      .then(response => {
        console.log('efewfewf', response);
        setError(response.isExist);
        if (response.isExist) {
          setName(joinName);
          setRoomName(mid);
          // @ts-ignore
          handleSubmit(joinName, mid);
        } else {
        }
      })
      .catch(err => {});
  };

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1">
        {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"}
      </Typography>
      <form onSubmit={handleRoomSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={joinName}
                onChange={handleNameChange}
              />
            </div>
          )}
          {!roomName && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-room-name">
                Room Name
              </InputLabel>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={mid}
                onChange={handleRoomNameChange}
              />
            </div>
          )}
        </div>
        {error === false && <span style={{ color: 'red' }}>Room Does not exist</span>}
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!joinName || !mid}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
